<template>
  <Member
    title="Dr. Francesco Puccetti"
    description="Chirurgia Generale e Chirurgia Gastroenterologica"
    text="Il Dott. Francesco Puccetti ha conseguito con lode la Laurea Specialistica in Medicina e Chirurgia presso l’Alma Mater Studiorum (Bologna) ed il Diploma di Specializzazione in Chirurgia dell’Apparato Digerente ed Endoscopia Digestiva presso l’Università degli Studi di Milano.

Il suo periodo formativo ed attività lavorativa sono stati contraddistinti dalla dedizione alla Chirurgia dell’Apparato Digerente, sviluppata sotto la guida del Prof. Rosati. Il suo interesse è stato principalmente rivolto alla Chirurgia Oncologica del tratto esofago-gastrico ed all’applicazione di metodiche innovative quali l’ottimizzazione del recupero post-operatorio (ERAS) e la tecnica mininvasiva.

Il suo percorso è stato contraddistinto da diverse opportunità di collaborazione con centri internazionali di riferimento nel trattamento della patologia esofago-gastrica. In particolare, ha conseguito due research fellowships della durata di 12 mesi ciascuna presso il Royal Marsden Hospital di Londra (UK) e il Virginia Mason Medical Center di Seattle, WA (USA).

Dal 2016 ha svolto attività clinico-chirurgica e di ricerca presso l’Ospedale San Raffaele di Milano, nella cui Unità di Chirurgia Gastroenterologica lavora tuttora in qualità di assistente chirurgo."
    :formation="[
      'Diploma di Maturità Scientifica conseguito nel 2004, presso Liceo Scientifico “Gregorio Ricci Curbastro” di Lugo (RA), indirizzo sperimentale Scientifico-Tecnologico.',
      'Laurea Specialistica in Medicina e Chirurgia conseguita con lode nel 2010, presso Alma Mater Studiorum (Bologna).',
      'Diploma di Specializzazione in Chirurgia dell’Apparato Digerente ed Endoscopia Digestiva conseguito con lode nel 2016, presso Università degli Studi di Milano.',
    ]"
    :pub="false"
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Puccetti",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
